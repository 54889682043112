(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  const Misc = {

    init() {
      $(document).ready( () => {
      });

      $(window).on('load', () => {
      });

      $(window).on('resize', () => {
      });
    }

  }

  module.exports = Misc;

})(jQuery, window, document);
